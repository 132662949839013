import React from "react";
import ChevronLeft from "./icons/ChevronLeft";

export const TextLink: React.FC<{ children: React.ReactNode }> = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <div className="flex flex-center link margin-bottom--md">
      <ChevronLeft title="back" titleId="back" className="icon icon--right" />
      {children}
    </div>
  );
};
