import type { Bundle, OperationOutcome, Patient, Resource } from "fhir/r4";
import { getActiveSpan } from "~/utils/tracer";

export const DEFAULT_UNEXPECTED_ERROR_READABLE_MESSAGE =
  "An unexpected response was received when contacting NHS services";
export const SDS_TOO_MANY_RESULTS =
  "Multiple results were found for this NHS number in the NHS' directory. Please contact the support team for assistance.";
export const SDS_NO_RESULTS =
  "This NHS number was not found in the NHS' directory. Please contact the support team for assistance.";

export function isPatient(resource: Resource): resource is Patient {
  return resource.resourceType === "Patient";
}

export function isPatientDeceased(patient: Patient): boolean {
  return !!patient.deceasedDateTime;
}

function isOperationOutcome(resource?: Resource): resource is OperationOutcome {
  return resource?.resourceType === "OperationOutcome";
}

export function extractErrorCode(resource: Resource): string | undefined {
  if (!isOperationOutcome(resource)) {
    return undefined;
  }

  return resource.issue?.find((iss) =>
    iss.details?.coding?.find((coding) => coding.code),
  )?.details?.coding?.[0].code;
}

const assertOperationOutcome =
  (expectedOutcomeCode: string) =>
  (resource: Resource): boolean =>
    isOperationOutcome(resource) &&
    extractErrorCode(resource) === expectedOutcomeCode;

export const isInvalidResourceIdOperationOutcome = assertOperationOutcome(
  "INVALID_RESOURCE_ID",
);

export const isResourceNotFoundOperationOutcome =
  assertOperationOutcome("RESOURCE_NOT_FOUND");

export const isInvalidatedResourceOperationOutcome = assertOperationOutcome(
  "INVALIDATED_RESOURCE",
);

export const isInvalidatedIdentifierSystemOperationOutcome =
  assertOperationOutcome("INVALID_IDENTIFIER_SYSTEM");

export const getDiagnosticsFromResource = (
  resource: Resource,
): string | undefined => {
  if (!isOperationOutcome(resource)) {
    return undefined;
  }

  return resource.issue?.find((iss) => iss.diagnostics)?.diagnostics;
};

export function getFullName(patient: Patient | undefined) {
  return patient?.name?.map(
    (name) => name.given?.join(" ") + " " + name.family,
  );
}

export function getGivenName(patient: Patient | undefined) {
  return patient?.name?.[0]?.given?.[0];
}

export const getNhsNumber = (patient: Patient | undefined) =>
  patient?.identifier?.find(
    (id) => id.system === "https://fhir.nhs.uk/Id/nhs-number",
  )?.value;

export const getGpPracticeOdsCode = (patient: Patient | undefined) => {
  // PDS API Specification states that a maximum number of 1 GP Practices will be returned
  const generalPractitioner = patient?.generalPractitioner?.[0];

  if (generalPractitioner) {
    return generalPractitioner.identifier?.system ===
      "https://fhir.nhs.uk/Id/ods-organization-code"
      ? generalPractitioner.identifier?.value
      : undefined;
  }
};

export const getNominatedPharmacyOdsCode = (patient: Patient | undefined) => {
  const pharmacy = patient?.extension?.find(
    (ext) =>
      ext.url ===
      "https://fhir.hl7.org.uk/StructureDefinition/Extension-UKCore-NominatedPharmacy",
  );

  if (pharmacy) {
    return pharmacy.valueIdentifier?.system ===
      "https://fhir.nhs.uk/Id/ods-organization-code"
      ? pharmacy.valueIdentifier?.value
      : undefined;
  }
};

export const isUnexpectedResponseException = (
  error: unknown,
): error is UnexpectedFhirV4ResponseException =>
  error instanceof UnexpectedFhirV4ResponseException;

export const isCodedException = (error: any): error is CodedFhirV4Exception =>
  error instanceof CodedFhirV4Exception;

export class CodedFhirV4Exception extends Error {
  constructor(
    message: string,
    public errorCode: string | undefined,
    public responseBody: string | undefined,
    public fhirHttpResponseStatus: number | undefined,
  ) {
    super(message);
    this.name = "CodedFhirV4Exception";
    getActiveSpan()?.setAttributes({
      "exception.class": "CodedFhirV4Exception",
      "exception.code": errorCode,
      "exception.response_body": responseBody,
      "exception.fhir_http_response_status": fhirHttpResponseStatus,
    });
  }
}

export class UnexpectedFhirV4ResponseException extends Error {
  constructor(
    message: string,
    public responseBody: string | undefined,
    public fhirHttpResponseStatus: number | undefined,
    public humanReadableMessage: string = DEFAULT_UNEXPECTED_ERROR_READABLE_MESSAGE,
  ) {
    super(message);
    this.name = "UnexpectedFhirV4ResponseException";
    getActiveSpan()?.setAttributes({
      "exception.class": "UnexpectedFhirV4ResponseException",
      "exception.response_body": responseBody,
      "exception.fhir_http_response_status": fhirHttpResponseStatus,
    });
  }
}

export function ensureValidFHIRV4Response(
  response: Response,
  responseBody: Bundle,
  operation: string,
) {
  if (!response?.ok || response?.status < 200 || response?.status >= 300) {
    const errorCode = extractErrorCode(responseBody);
    const errorMessage = `Error in FHIR operation: ${operation}`;
    const errorResponseBody = JSON.stringify(responseBody);

    // If an error code can be extracted, it is therefore a coded exception
    if (errorCode) {
      // TODO: ensure we include this innerException in Honeycomb trace
      throw new CodedFhirV4Exception(
        errorMessage,
        errorCode,
        errorResponseBody,
        response.status,
      );
    }

    // Otherwise, an unexpected/uncoded exception
    throw new UnexpectedFhirV4ResponseException(
      errorMessage,
      errorResponseBody,
      response.status,
    );
  }

  // Defensive code.  We do not support multiple results and pagination yet,
  // so throw if we get more than one result, or zero results, for any call
  if (isNotNullOrUndefined(responseBody?.total) && responseBody.total !== 1) {
    const msg = `Error in FHIR operation: ${operation}: ${responseBody.total} results returned from SDS`;
    const humanReadableMessage =
      responseBody.total! > 1 ? SDS_TOO_MANY_RESULTS : SDS_NO_RESULTS;

    throw new UnexpectedFhirV4ResponseException(
      msg,
      JSON.stringify(responseBody),
      response.status,
      humanReadableMessage,
    );
  }
}

function isNotNullOrUndefined(thing: any) {
  return thing !== null && thing !== undefined;
}
